import React from 'react';
import styled from 'styled-components';
import {
    Combobox,
    Heading,
    IconButton,
    Label,
    Pane,
    PlusIcon,
    Switch,
    Table,
    TextInput,
    TrashIcon,
} from 'evergreen-ui';

import useFetchDataTypes from '../../../../hooks/useFetchDataTypes';
import {
    withEventTargetAsNumber,
    withEventTargetAsValue,
} from '../../../../utils/common';
import { actions } from '../models/reducer';
import {
    useDispatchContext,
    useStateContext,
} from '../../../../context/createStateReducerContext';

import { CollectorDestinationInput } from './DestinationInputs';
import { CollectorMetadataInputs } from './MetadataInputs';
import { noUniqueCharsRegex, PORT_MIN, PORT_MAX } from './utils';

const FlexButton = styled(Pane)`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 900px) {
        margin-top: 1rem;
    } ;
`;

const AddButton = styled(IconButton)`
    margin-top: 2rem;
    border-radius: 50%;
    background: #265a7f;
    color: #fff;
    svg: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    bottom: 1.25rem;
    left: 0.55rem;

    &:hover, &:active {
        background: #517b98 !important; # not ideal
    }
`;

const DeleteButton = styled(IconButton)`
    border-radius: 50%;
    background: #ef5757;
    color: #fff;
    svg: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    &:hover,
    &:active {
        background: #f27573 !important;
    }

    &:focus {
        box-shadow: 0 0 0 3px #ee534f3d, inset 0 0 0 1px #ee534f3d,
            inset 0 -1px 1px 0 #ee534f3d !important;
    }

    @media (max-width: 900px) {
        border-radius: 5px;
        width: 100%;
        height: 32px;
    } ;
`;

function CollectorInputs({ collector, dataTypes }) {
    const state = useStateContext();
    const [showMetadata, setShowMetadata] = React.useState(false);
    const [readOnlyPort, setReadOnlyPort] = React.useState(false);
    const [readOnlyDataType, setReadOnlyDataType] = React.useState(false);

    const dispatch = useDispatchContext();
    const { common, syslog } = collector;

    const { port, tlsEnabled, frame_type = null } = syslog;
    const { comment, dataType } = common;

    React.useEffect(() => {
        setReadOnlyPort(!!port);
        setReadOnlyDataType(!!dataType);
    }, []);

    function dispatchAction(type) {
        return function updateState(value) {
            dispatch({ type, payload: { collector, value } });
        };
    }

    const commentValid =
        noUniqueCharsRegex().test(comment) ||
        common.comment.length === 0 ||
        !common.comment;

    const portIsUnique =
        state.collectors.filter((collector) => collector?.syslog?.port === port)
            .length === 1;

    const portIsInValidRange = port >= PORT_MIN && port <= PORT_MAX;

    const updateDataType = dispatchAction(
        actions.UPDATE_COMMON_COLLECTOR_DATA_TYPE,
    );
    const updatePortValue = withEventTargetAsNumber(
        dispatchAction(actions.UPDATE_SYSLOG_COLLECTOR_PORT),
    );
    const updateCommentValue = withEventTargetAsValue(
        dispatchAction(actions.UPDATE_COMMON_COLLECTOR_COMMENT),
    );
    const updateTlsEnabledValue = dispatchAction(
        actions.UPDATE_SYSLOG_COLLECTOR_TLS_ENABLED,
    );
    const updateOctetCountEnabledValue = dispatchAction(
        actions.UPDATE_SYSLOG_COLLECTOR_OCTET_COUNT_ENABLED,
    );
    const handleSwitchTlsEnabled = () => updateTlsEnabledValue(!tlsEnabled);
    const handleSwitchOctetCountEnabled = () =>
        updateOctetCountEnabledValue(!frame_type ? 'octet_count' : null);
    const handleRemoveCollector = () =>
        dispatch({ type: actions.REMOVE_COLLECTOR, payload: { collector } });

    return (
        <>
            <Pane
                key={collector.tagId}
                data-testid="cdp-table-form-row"
                borderBottom="1px solid #d8dae5"
                paddingTop="1.25rem"
                paddingBottom="1.25rem"
            >
                <Pane display="flex">
                    <Pane width="25%">
                        <Label display="block" marginBottom="0.25rem">
                            Data Type
                        </Label>
                        <Combobox
                            data-testid="dataType-element"
                            width="calc(100% - 0.5rem)"
                            openOnFocus
                            items={dataTypes}
                            value={dataType}
                            disabled={readOnlyDataType}
                            initialSelectedItem={dataType}
                            onChange={updateDataType}
                        />
                    </Pane>
                    <Pane width="25%">
                        <Label display="block" marginBottom="0.25rem">
                            Port
                        </Label>
                        <TextInput
                            data-testid="port-element"
                            width="calc(100% - 0.5rem)"
                            value={port || ''}
                            onChange={updatePortValue}
                            readOnly={readOnlyPort}
                            isInvalid={
                                !port ||
                                !portIsInValidRange ||
                                (!readOnlyPort && !portIsUnique)
                            }
                            required
                        />
                        {(!portIsUnique || !portIsInValidRange || !port) && (
                            <Label
                                display="block"
                                marginBottom="0.25rem"
                                color="#D14343"
                            >
                                {!port && 'Required'}
                                {!portIsUnique &&
                                    !readOnlyPort &&
                                    'Port numbers must be unique'}
                                {!portIsInValidRange &&
                                    Boolean(port) &&
                                    'Port numbers must be between 30000 and 32767'}
                            </Label>
                        )}
                    </Pane>
                    <Pane width="25%">
                        <Label display="block" marginBottom="0.25rem">
                            Comments
                        </Label>
                        <TextInput
                            width="calc(100% - 0.5rem)"
                            value={comment || ''}
                            onChange={updateCommentValue}
                            isInvalid={!commentValid}
                        />
                        {!commentValid && (
                            <Label display="block" color="#D14343">
                                No special characters
                            </Label>
                        )}
                    </Pane>
                    <Pane width="5%" marginLeft="2rem">
                        <Label display="block" marginBottom="0.25rem">
                            TLS
                        </Label>
                        <Switch
                            display="block"
                            checked={tlsEnabled}
                            onChange={handleSwitchTlsEnabled}
                            marginTop="6%"
                        />
                    </Pane>
                    <Pane width="5%" marginLeft="2rem">
                        <Label display="block" marginBottom="0.25rem">
                            OctetCount
                        </Label>
                        <Switch
                            display="block"
                            checked={!!frame_type}
                            onChange={handleSwitchOctetCountEnabled}
                            marginTop="6%"
                        />
                    </Pane>
                    <Pane width="8%" marginLeft="2rem">
                        <Label display="block" marginBottom="0.25rem">
                            Destinations
                        </Label>
                        <CollectorDestinationInput collector={collector} />
                    </Pane>
                    <FlexButton className="flex-child">
                        <DeleteButton
                            icon={<TrashIcon color="#fff" />}
                            height={40}
                            onClick={handleRemoveCollector}
                            data-testid="remove-data-btn"
                            type="button"
                        />
                    </FlexButton>
                </Pane>
                <Pane display="flex">
                    <Pane width="25%">
                        <Pane display="flex">
                            <Switch
                                checked={showMetadata}
                                onChange={() => setShowMetadata(!showMetadata)}
                                marginRight="0.5rem"
                                id={`switch-${collector.tagId}`}
                                marginLeft="0.175rem"
                            />
                            <Label htmlFor={`switch-${collector.tagId}`}>
                                Configure Metadata
                            </Label>
                        </Pane>
                    </Pane>
                </Pane>
                <Pane>
                    {showMetadata && (
                        <>
                            <CollectorMetadataInputs collector={collector} />
                        </>
                    )}
                </Pane>
            </Pane>
        </>
    );
}

function CollectorsSection() {
    const state = useStateContext();
    const dispatch = useDispatchContext();
    const dataTypes = useFetchDataTypes();
    const collectors = state.collectors || [];

    const insertCollectorInState = () =>
        dispatch({ type: actions.INSERT_BASE_CDP_COLLECTOR });

    return (
        <>
            <Heading size={700} id="collectors-table" marginBottom="0.75rem">
                Data Sources
            </Heading>
            <Pane
                data-testid="cdp-config-form-table"
                aria-labelledby="collectors-table"
            >
                <Table.Body>
                    {collectors.map((collector, index) => (
                        <Pane key={`collector-inputs-${index}`}>
                            <CollectorInputs
                                collector={collector}
                                dataTypes={dataTypes}
                                key={`form-row-${collector.tagId}`}
                            />
                        </Pane>
                    ))}
                    <AddButton
                        icon={<PlusIcon color="#fff" />}
                        height={40}
                        onClick={insertCollectorInState}
                        aria-label="add data type"
                        data-testid="add-data-btn"
                        type="button"
                    />
                </Table.Body>
            </Pane>
        </>
    );
}

export default CollectorsSection;
